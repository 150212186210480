export const untilCondition = (callback: () => boolean, timerMs = 100): Promise<void> => {
    return new Promise((resolve) => {
        const handle = () => {
            if (!callback()) {
                return
            }

            stop()
            resolve()
        }

        const { stop } = useTimer(handle, timerMs, {
            interval: true,
            immediate: true,
        })

        handle()
    })
}
