export type UseScriptTag = {
    scriptTag: Ref<HTMLScriptElement | null>
    load: (waitForScriptLoad?: boolean) => Promise<HTMLScriptElement | boolean>
    unload: () => void
}

export type UseScriptTagOptions = {
    immediate?: boolean
    async?: boolean
    type?: string
    manual?: boolean
    crossOrigin?: 'anonymous' | 'use-credentials'
    referrerPolicy?: 'no-referrer' | 'no-referrer-when-downgrade' | 'origin' | 'origin-when-cross-origin' | 'same-origin' | 'strict-origin' | 'strict-origin-when-cross-origin' | 'unsafe-url'
    noModule?: boolean
    defer?: boolean
    attrs?: Record<string, string>
}

export const useScriptTag = (
    src: MaybeRefOrGetter<string>,
    onLoaded: (el: HTMLScriptElement) => void = noop,
    options: UseScriptTagOptions = {},
): UseScriptTag => {
    const { document } = getClientContext()

    const {
        immediate = false,
        manual = false,
        type = 'text/javascript',
        async = false,
        crossOrigin,
        referrerPolicy,
        noModule,
        defer,
        attrs = {},
    } = options

    const scriptTag = ref<UseScriptTag['scriptTag']>(null)

    let _promise: Promise<HTMLScriptElement | boolean> | null = null

    const loadScript = (waitForScriptLoad: boolean): Promise<HTMLScriptElement | boolean> => {
        return new Promise((resolve, reject) => {
            const resolveWithElement = (el: HTMLScriptElement) => {
                scriptTag.value = el

                resolve(el)

                return el
            }

            if (!document) {
                resolve(false)

                return
            }

            let shouldAppend = false

            let el = document.querySelector<HTMLScriptElement>(`script[src="${ toValue(src) }"]`)

            if (!el) {
                el = document.createElement('script')
                el.type = type
                el.async = async
                el.src = toValue(src)

                if (defer) {
                    el.defer = defer
                }

                if (crossOrigin) {
                    el.crossOrigin = crossOrigin
                }

                if (noModule) {
                    el.noModule = noModule
                }

                if (referrerPolicy) {
                    el.referrerPolicy = referrerPolicy
                }

                Object.entries(attrs).forEach(([ name, value ]) => el?.setAttribute(name, value))

                shouldAppend = true
            } else if (el.hasAttribute('data-loaded')) {
                resolveWithElement(el)
            }

            el.addEventListener('error', event => reject(event))
            el.addEventListener('abort', event => reject(event))
            el.addEventListener('load', () => {
                el!.setAttribute('data-loaded', 'true')

                onLoaded(el!)

                resolveWithElement(el!)
            })

            if (shouldAppend) {
                el = document.head.appendChild(el)
            }

            if (!waitForScriptLoad) {
                resolveWithElement(el)
            }
        })
    }

    const load: UseScriptTag['load'] = (waitForScriptLoad = true) => {
        if (!_promise) {
            _promise = loadScript(waitForScriptLoad)
        }

        return _promise
    }

    const unload: UseScriptTag['unload'] = () => {
        if (!document) {
            return
        }

        _promise = null

        if (scriptTag.value) {
            scriptTag.value = null
        }

        const el = document.querySelector<HTMLScriptElement>(`script[src="${ toValue(src) }"]`)

        if (el) {
            document.head.removeChild(el)
        }
    }

    if (immediate && !manual) {
        tryOnMounted(load)
    }

    if (!manual) {
        tryOnUnmounted(unload)
    }

    return {
        scriptTag,
        load,
        unload,
    }
}
