import type { SpilkySDK } from '~'

type Options = {
    preview?: boolean
    demo?: boolean
    debug?: boolean
    onlyChat?: boolean
    onlyKB?: boolean
    preferablyOpenChat?: boolean
    preferablyOpenKB?: boolean
    preventWidgetClosing?: boolean
    openWidgetImmediately?: boolean
}

export type UseWidget = {
    isLoaded: Ref<boolean>
    updateOptions: (options: Options) => void
    showWidget: (frameFor: string) => Promise<void>
    hideWidget: VoidFunction
    onSetup?: (fn: (sdk: SpilkySDK) => void) => VoidFunction
    onLoaded?: (fn: (sdk: SpilkySDK) => void) => VoidFunction
    onBeforeShow?: (fn: (sdk: SpilkySDK) => void) => VoidFunction
}

export const widgetScriptEl = shallowRef<HTMLScriptElement | undefined>()
export const isWidgetLoaded = computed<boolean>(() => !!widgetScriptEl.value)

export const useWidget = (code: string, options: Options = {}): UseWidget => {
    const { public: { isProd } } = useRuntimeConfig()
    const { window, document } = getClientContext()

    options.debug = options.debug ?? !isProd

    const hooks = {
        onSetup: createEventHook(),
        onLoaded: createEventHook(),
        onBeforeShow: createEventHook(),
    }

    const onSetup = hooks.onSetup.on
    const onLoaded = hooks.onLoaded.on
    const onBeforeShow = hooks.onBeforeShow.on

    const updateOptions = (newOptions: Options): void => {
        Object.assign(options, newOptions)
    }

    const loadWidget = (): Promise<void> => {
        return new Promise((resolve) => {
            if (!window.SpilkySDK || !window.SpilkySDK.q) {
                window.SpilkySDK = function (...args: any[]) {
                    (window.SpilkySDK.q = window.SpilkySDK.q || []).push(args)
                }
            }

            window.SpilkySDK.preview = options.preview
            window.SpilkySDK.demo = options.demo
            window.SpilkySDK.debug = options.debug
            window.SpilkySDK.onlyChat = options.onlyChat
            window.SpilkySDK.onlyKB = options.onlyKB
            window.SpilkySDK.preferablyOpenChat = options.preferablyOpenChat
            window.SpilkySDK.preferablyOpenKB = options.preferablyOpenKB
            window.SpilkySDK.preventWidgetClosing = options.preventWidgetClosing
            window.SpilkySDK.openWidgetImmediately = options.openWidgetImmediately

            const { load } = useScriptTag(
                useRuntimeConfig().public.apiUrl + '/widget/sdk/' + code,
                async (el) => {
                    widgetScriptEl.value = el

                    await hooks.onLoaded.trigger(window.SpilkySDK)

                    // window.SpilkySDK.frameName встановлюється у скрипті лоадера віджета
                    untilCondition(() => !!window.SpilkySDK.frameName)
                        .then(resolve)
                },
                { async: true },
            )

            hooks.onSetup.trigger(window.SpilkySDK)
                .then(() => load())
        })
    }

    const unloadWidget = (): void => {
        getFrame()?.remove()

        window.SpilkySDKHandlerDestroy?.()

        widgetScriptEl.value.remove()
        widgetScriptEl.value = undefined

        delete window.SpilkySDK
        delete window.SpilkySDKHandlerDestroy
    }

    const getFrame = (): HTMLIFrameElement | undefined => {
        return document.querySelector(`iframe[name=${ window.SpilkySDK?.frameName }]`) || undefined
    }

    const showWidget = async (frameFor: string): Promise<void> => {
        if (!isWidgetLoaded.value) {
            await loadWidget()

            getFrame().dataset.for = frameFor
        }

        const frame = getFrame()

        if (!frame) {
            return
        }

        if (frame.dataset.for !== frameFor) {
            unloadWidget()

            setTimeout(() => showWidget(frameFor), 0)

            return
        }

        if (+frame.dataset.active) {
            return
        }

        frame.dataset.active = '1'

        await hooks.onBeforeShow.trigger(window.SpilkySDK)

        window.SpilkySDK('toggleWidgetVisibility', 1)
    }

    const hideWidget = (): void => {
        const frame = getFrame()

        if (!frame) {
            return
        }

        frame.dataset.active = '0'

        window.SpilkySDK('toggleWidgetVisibility', 0)
    }

    return {
        isLoaded: isWidgetLoaded,
        updateOptions,
        showWidget,
        hideWidget,
        onSetup,
        onLoaded,
        onBeforeShow,
    }
}
